import { Grid } from '@mui/material';
import './contact.css';

const Contact = () => {
  var fontColor = '#2F8994';
  return (
    <>
      <Grid item sx={{ height: '500px', width: '80%' }}>
        <Grid item>
          <h1>Contact Us</h1>
        </Grid>
        <Grid item>
          Hello! If you'd like to book a lesson or have a question, you can
          either
          <Grid
            item
            sx={{
              fontSize: '20px',
              padding: '16px',
              color: fontColor,
            }}
          >
            <a href="www.facebook.com"> Send us a message on facebook</a>
          </Grid>
        </Grid>
        <div className="hr" />
        <Grid item>Send us a quick email</Grid>
        <Grid
          item
          sx={{
            backgroundColor: 'lightblue',
            margin: '20px',
            borderRadius: '8px',
            padding: '8px',
          }}
        >
          FORM GO HERE
          <Grid
            item
            sx={{
              margin: '8px',
              backgroundColor: 'white',
              borderRadius: '8px',
            }}
          >
            emal
          </Grid>
          <Grid
            item
            sx={{
              margin: '8px',
              backgroundColor: 'white',
              borderRadius: '8px',
            }}
          >
            content
          </Grid>
        </Grid>
        <div className="hr" />
        <Grid item>Or text or call us on</Grid>
        <Grid item sx={{ fontSize: '28px', margin: '8px', color: fontColor }}>
          0418 894 647
        </Grid>
        make this a linky thing
      </Grid>
    </>
  );
};

export default Contact;
