import { Grid } from '@mui/material';

const Originals = () => {
  return (
    <>
      <Grid item sx={{ height: '500px' }}>
        <Grid item>
          <h1>Bellbird Originals</h1>
        </Grid>
        oiwjeofijwoejfowejowijefoijeofjw
      </Grid>
    </>
  );
};

export default Originals;
