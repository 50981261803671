import { Grid } from '@mui/material';

const Events = () => {
  return (
    <>
      <Grid item>
        <Grid item>
          <h1>Events</h1>
        </Grid>
        <img
          src={require('../../assets/christmas1.png')}
          alt="Christmas Concert"
        />
      </Grid>
    </>
  );
};

export default Events;
