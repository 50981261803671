import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
} from '@mui/material';

const SkyeDetails = () =>
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vestibulum leo non ultricies rutrum. Integer convallis nisl at massa fringilla fermentum. Sed consectetur consectetur consectetur. Vestibulum sem augue, maximus ultricies lorem vitae, maximus vestibulum dui. Nunc tristique tempor est, eget lacinia elit laoreet eget. Ut in tempus felis. Nunc eu ex sapien. Pellentesque pulvinar consequat vulputate. Integer efficitur nisl in sem elementum, ac volutpat mauris faucibus.';

const Teachers = () => {
  return (
    <>
      <Grid item>
        <h1>Our Teachers</h1>
      </Grid>
      <Grid item container justifyContent="center">
        <Card
          sx={{
            maxWidth: 300,
            margin: '16px',
          }}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="200"
              image={require('../../assets/skye.jpg')}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                SKYEEEE
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <SkyeDetails />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 300, margin: '16px' }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="200"
              image={require('../../assets/skye.jpg')}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                not skye
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <SkyeDetails />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

export default Teachers;
