import './App.css';
import { useState } from 'react';
import { Grid } from '@mui/material';
import Footer from './components/footer';
import Home from './pages/home/home';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Originals from './pages/originals/originals';
import Testimonials from './pages/testimonials/testimonials';
import Lessons from './pages/lessons/lessons';
import Teachers from './pages/teachers/teachers';
import Events from './pages/events/events';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function App() {
  const [page, setPage] = useState('home');
  const primary = '#7EA9AF';

  return (
    <Router>
      <div>
        <nav>
          <Grid
            container
            className="nav-container"
            sx={{
              height: '100vh',
              backgroundColor: primary,
              position: 'absolute',
            }}
          >
            <Grid
              container
              alignItems="center"
              sx={{
                height: '70%',
                textAlign: 'center',
                color: 'white',
                whiteSpace: 'nowrap',
                div: {},
                '& a': {
                  width: '100%',
                  cursor: 'pointer',
                  '& div': {
                    padding: '16px',
                  },
                },
              }}
            >
              <Link to="/" onClick={() => setPage('home')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'home' ? 'white' : 'none',
                    color: page === 'home' ? primary : 'inherit',
                  }}
                >
                  Home
                </Grid>
              </Link>
              <Link to="/teachers" onClick={() => setPage('teachers')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'teachers' ? 'white' : 'none',
                    color: page === 'teachers' ? primary : 'inherit',
                  }}
                >
                  Teachers
                </Grid>
              </Link>
              <Link to="/about" onClick={() => setPage('about')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'about' ? 'white' : 'none',
                    color: page === 'about' ? primary : 'inherit',
                  }}
                >
                  About
                </Grid>
              </Link>
              <Link to="/originals" onClick={() => setPage('originals')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'originals' ? 'white' : 'none',
                    color: page === 'originals' ? primary : 'inherit',
                  }}
                >
                  Bellbird Originals
                </Grid>
              </Link>
              <Link to="/events" onClick={() => setPage('events')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'events' ? 'white' : 'none',
                    color: page === 'events' ? primary : 'inherit',
                  }}
                >
                  Events
                </Grid>
              </Link>
              <Link to="/lessons" onClick={() => setPage('lessons')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'lessons' ? 'white' : 'none',
                    color: page === 'lessons' ? primary : 'inherit',
                  }}
                >
                  Lesson Info
                </Grid>
              </Link>
              <Link to="/testimonials" onClick={() => setPage('testimonials')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'testimonials' ? 'white' : 'none',
                    color: page === 'testimonials' ? primary : 'inherit',
                  }}
                >
                  Testimonials
                </Grid>
              </Link>
              <Link to="/contact" onClick={() => setPage('contact')}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: page === 'contact' ? 'white' : 'none',
                    color: page === 'contact' ? primary : 'inherit',
                  }}
                >
                  Contact
                </Grid>
              </Link>
            </Grid>
            <Grid container className="img">
              <img
                src={require('./assets/logo.png')}
                alt="Bellbird Music logo"
              />
            </Grid>
          </Grid>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Grid container className="body">
          <Grid
            item
            sx={{
              width: '40%',
              margin: '16px 0',
            }}
          >
            <img src={require('./assets/nameBlack.png')} alt="Bellbird Music" />
          </Grid>
          <Routes>
            <Route path="/teachers" element={<Teachers />} />
            <Route path="/about" element={<About />} />
            <Route path="/originals" element={<Originals />} />
            <Route path="/events" element={<Events />} />
            <Route path="/lessons" element={<Lessons />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </Grid>
      </div>
    </Router>
  );
}

export default App;
