import { Grid } from '@mui/material';

const Footer = () => {
  return (
    <Grid
      item
      container
      sx={{
        width: '100%',
        backgroundColor: '#F1F1F1',
        padding: '16px',
        paddingBottom: '8px',
        div: {
          padding: '0 8px 32px',
        },
        marginTop: '20px',
      }}
    >
      <Grid
        item
        xs={3}
        sx={{
          borderRight: '2px solid #D0D0D0',
        }}
      >
        <h4>Lessons</h4>
        <div>
          We specialise in lessons for the modern day musician of any age.
        </div>
        <div>Piano</div>
        <div>Guitar</div>
        <div>Music Theory</div>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          borderRight: '2px solid #D0D0D0',
        }}
      >
        <h4>Location</h4>
        We offer lessons within the beautiful town of Dayboro and surrounds.
      </Grid>
      <Grid
        item
        className={'socials'}
        xs={3}
        sx={{
          borderRight: '2px solid #D0D0D0',
        }}
      >
        <h4>Social Media</h4>
        <Grid item>Follow Bellbird Music Lessons on</Grid>
        <a
          href="https://www.facebook.com/BellbirdMusicLessons"
          target="_blank"
          rel="noreferrer"
        >
          <img src={require('../assets/facebook.ico')} alt="Piano Keys" />
          <Grid item>Facebook</Grid>
        </a>
        <a
          href="https://www.instagram.com/bellbird.music.lessons/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={require('../assets/facebook.ico')} alt="Piano Keys" />
          <Grid item>Instagram</Grid>
        </a>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          borderRight: '0',
        }}
      >
        <h4>Contact Us</h4>
        <Grid item>
          Mobile: <br />
          0418 894 647
        </Grid>
        <Grid item>Email: bellbirdmusiclessons@outlook.com</Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
