import { Grid } from '@mui/material';

const Testimonials = () => {
  return (
    <>
      <Grid item sx={{ height: '500px' }}>
        <h1>Testimonials</h1>
        <Grid
          item
          sx={{
            color: 'gray',
            fontSize: '20px',
            padding: '8px',
            fontStyle: 'italic',
          }}
        >
          "If you are looking for an amazing music teacher you can stop
          looking.... Skye is a wonderful teacher, her combination of musical
          knowledge, enthusiasm, kindness and patience means she can help anyone
          achieve their best."
        </Grid>
        <Grid item>~ Katie Jeffery</Grid>
        ADD GOOGLE REVIEWS
      </Grid>
    </>
  );
};

export default Testimonials;
