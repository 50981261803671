import { Grid } from '@mui/material';

const Lessons = () => {
  return (
    <Grid item sx={{ height: '500px', width: '100%' }}>
      <Grid item>
        <h1>Lessons</h1>
      </Grid>
      <Grid container item>
        <Grid item xs={6}>
          PIANO LESSONS
          <Grid item>piano lesson info...</Grid>
        </Grid>
        <Grid item xs={6}>
          GUITAR LESSONS
          <Grid item>guitar lesson info...</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Lessons;
