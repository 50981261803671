import { Grid } from '@mui/material';

const About = () => {
  return (
    <>
      <Grid item sx={{ height: '500px' }}>
        <Grid item>
          <h1>About</h1>
        </Grid>
        ABOUT STUFF???
      </Grid>
    </>
  );
};

export default About;
