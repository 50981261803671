import { Grid } from '@mui/material';
import './home.css';

const IntroText = () =>
  'Welcome to Bellbird Music! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vestibulum leo non ultricies rutrum. Integer convallis nisl at massa fringilla fermentum. Sed consectetur consectetur consectetur. Vestibulum sem augue, maximus ultricies lorem vitae, maximus vestibulum dui. Nunc tristique tempor est, eget lacinia elit laoreet eget. Ut in tempus felis. Nunc eu ex sapien. Pellentesque pulvinar consequat vulputate. Integer efficitur nisl in sem elementum, ac volutpat mauris faucibus.';

const Home = () => {
  return (
    <>
      <Grid
        item
        sx={{
          padding: '16px',
          marginBottom: '16px',
        }}
      >
        <IntroText />
      </Grid>
      <Grid item>
        <table>
          <tr>
            <td>
              <img
                src={require('../../assets/pianoKeys.jpeg')}
                alt="Piano Keys"
              />
            </td>
            <td>
              <img src={require('../../assets/pianoOpen.jpg')} alt="Piano" />
            </td>
          </tr>
        </table>
      </Grid>
      <Grid
        item
        sx={{
          padding: '16px',
          marginBottom: '16px',
        }}
      >
        <IntroText />
      </Grid>
    </>
  );
};

export default Home;
